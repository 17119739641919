<template>
  <v-app style="background-color: #F7F9FB;">
    <app-bar />
    <left-menu />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <ag-notify />
  </v-app>
</template>

<script>
import AppBar from 'src/components/layout/AppBar'
import LeftMenu from 'src/components/layout/LeftMenu'
import AgNotify from 'src/components/common/AgNotify'

export default {
  name: 'DefaultLayout',
  components: {
    AppBar,
    LeftMenu,
    AgNotify,
  },
}
</script>
