<template>
  <v-app-bar
    color="white"
    app
    clipped-right
    style="z-index: 6"
  >
    <v-btn
      fab
      small
      class="mr-2"
      @click.stop="toggleCollapsedMenu"
    >
      <v-icon
        color="primary"
        v-text="isMenuCollapsed ? 'chevron_right' : 'chevron_left'"
      />
    </v-btn>
    <v-btn fab small @click.stop="toggleMenu">
      <v-icon color="primary">
        menu
      </v-icon>
    </v-btn>

    <v-spacer />

    <v-toolbar-title v-text="'Flowmem Admin'" />

    <v-spacer />

    <current-user-button-menu />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import CurrentUserButtonMenu from './CurrentUserButtonMenu'

export default {
  name: 'AppBar',
  components: {
    CurrentUserButtonMenu,
  },
  computed: {
    ...mapGetters('ui', {
      isMenuCollapsed: 'isMenuCollapsed',
    }),
  },
  methods: {
    ...mapActions('ui', {
      toggleMenu: 'toggleMenu',
      toggleCollapsedMenu: 'toggleCollapsedMenu',
    }),
  },
}
</script>
