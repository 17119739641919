<template>
  <v-menu
    bottom
    offset-y
    origin="top"
    transition="scale-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        outlined
        color="accent"
        class="mr-2"
        v-on="on"
        v-text="fullname"
      />
    </template>

    <v-list flat nav>
      <v-list-item dense @click="logout">
        <v-list-item-icon>
          <v-icon>power_settings_new</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="'Déconnexion'" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CurrentUserButtonMenu',
  computed: {
    ...mapGetters('me', {
      fullname: 'fullname',
    }),
  },
  methods: {
    ...mapActions('me', {
      logout: 'logout',
    }),
  },
}
</script>
