export default [
  {
    title: 'Accueil',
    icon: 'home',
    link: '/',
    roleLevelRequire: 3,
  },
  {
    title: 'Gammes',
    icon: 'playlist_add_check',
    children: [
      {
        roleLevelRequire: 3, icon: 'content_copy', title: 'Références', link: '/part-number', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'style', title: 'Types de références', link: '/part-type', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'build', title: 'Catégories d\'opérations', link: '/operation-category', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'build', title: 'Type d\'opérations', link: '/operation-type', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'done_all', title: 'Type de relevés', link: '/record-type', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'insert_drive_file', title: 'Documents', link: '/document', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'delete', title: 'Causes de rebut', link: '/rejection-cause', shortcut: { home: true },
      },
    ],
    roleLevelRequire: 3,
  },
  {
    title: 'Production',
    icon: 'build',
    children: [
      {
        roleLevelRequire: 3, icon: 'list_alt', title: 'Ordres de fabrication', link: '/batch', shortcut: { home: true },
      },
    ],
    roleLevelRequire: 3,
  },
  {
    title: 'Données',
    icon: 'equalizer',
    children: [
      {
        roleLevelRequire: 3, icon: 'arrow_upward', title: 'Traçabilité ascendante', link: '/traceability-asc', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'arrow_downward', title: 'Traçabilité descendante', link: '/traceability-desc', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'file_download', title: 'Export', link: '/export', shortcut: { home: true },
      },
    ],
    roleLevelRequire: 3,
  },
  {
    title: 'KPI',
    icon: 'pie_chart',
    children: [
      {
        roleLevelRequire: 3, icon: 'functions', title: 'Production', link: '/production', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'play_circle_outline', title: 'En cours', link: '/in-progress', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'dashboard', title: 'Journalier', link: '/daily-kpi', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'donut_large', title: 'Macro', link: '/macro-kpi', shortcut: { home: true },
      },
    ],
    roleLevelRequire: 3,
  },
  {
    title: 'Pièces',
    icon: 'app_registration',
    children: [
      {
        roleLevelRequire: 3, icon: 'app_registration', title: 'Rendre disponible', link: '/set-part-to-use', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'app_registration', title: 'Déclarer consommée', link: '/set-part-used', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'app_registration', title: 'Rebut en stock', link: '/set-rejected-part-from-stock', shortcut: { home: true },
      },
      {
        roleLevelRequire: 3, icon: 'app_registration', title: 'Annuler un rebut en stock', link: '/unset-rejected-part-from-stock', shortcut: { home: true },
      },
    ],
    roleLevelRequire: 3,
  },
  {
    title: 'Administration',
    icon: 'settings',
    children: [
      {
        roleLevelRequire: 4, icon: 'supervised_user_circle', title: 'Utilisateurs', link: '/user', shortcut: { home: true },
      },
      {
        roleLevelRequire: 4, icon: 'verified_user', title: 'Rôles', link: '/role', shortcut: { home: true },
      },
    ],
    roleLevelRequire: 4,
  },
]
