import MENU_ITEMS from './menu-items'

function getAllowedMenuFilter (role) {
  return item => role.level >= item.roleLevelRequire
}

function filterAllowedMenu (menuList, role) {
  return menuList
    .filter(getAllowedMenuFilter(role))
    .map(({ children, ...menu }) => ({
      ...menu,
      ...(children ? { children: filterAllowedMenu(children, role) } : {}),
    }))
}

function getMenuList (role) {
  return filterAllowedMenu(MENU_ITEMS, role)
}

function flattenChildren (list) {
  return list.reduce((acc, item) => ([...acc, item, ...flattenChildren(item.children || [])]), [])
}

function getShortcutList (name, role) {
  const menuList = getMenuList(role)
  return flattenChildren(menuList)
    .filter(item => item.shortcut?.[name])
}

export default {
  getMenuList,
  getShortcutList,
}
