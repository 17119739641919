<template>
  <v-navigation-drawer
    :value="isMenuVisible"
    :mini-variant="showCollapsedMenu"
    :expand-on-hover="showCollapsedMenu"
    dark
    color="primary"
    persistent
    enable-resize-watcher
    fixed
    app
    mini-variant-width="75"
    @input="setMenu"
  >
    <v-list-item two-line>
      <v-list-item-avatar class="company-icon">
        <v-icon large color="white" class="ml-1">
          domain
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="font-weight-regular">
          <span
            class="logo-normal"
            :class="{ 'show-logo': !showCollapsedMenu }"
            v-text="'Flowmem'"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <v-list nav>
      <template v-for="menu in menuList">
        <v-list-item
          v-if="!menu.children || !menu.children.length"
          :key="menu.title"
          :to="menu.link"
          class="list-menu"
        >
          <v-list-item-icon class="ml-1">
            <v-icon large v-text="menu.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="menu.title" />
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-else
          :key="menu.title"
          class="list-menu"
          no-action
        >
          <template v-slot:prependIcon>
            <v-icon large class="ml-1" v-text="menu.icon" />
          </template>
          <template v-slot:activator>
            <v-list-item-title v-text="menu.title" />
          </template>

          <v-list-item
            v-for="(submenu, i) in menu.children"
            :key="i + submenu.title"
            :to="submenu.link"
          >
            <v-list-item-content>
              <v-list-item-title v-text="submenu.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MenuService from 'src/services/menu'

export default {
  name: 'LeftMenu',
  computed: {
    ...mapGetters('me', {
      role: 'role',
    }),
    ...mapGetters('ui', {
      isMenuVisible: 'isMenuVisible',
      isMenuCollapsed: 'isMenuCollapsed',
    }),
    showCollapsedMenu () {
      return this.isMenuCollapsed && this.$vuetify.breakpoint.lgAndUp
    },
    menuList () {
      return MenuService.getMenuList(this.role)
    },
  },
  methods: {
    ...mapActions('ui', {
      toggleMenu: 'toggleMenu',
      setMenu: 'setMenu',
    }),
  },
}
</script>

<style>
.list-menu .v-list-group__items > .v-list-item {
    padding-left: 30px !important;
}

.list-menu .v-list-group__header__append-icon {
    margin-left: 0px !important;
}

.list-menu .v-list-item__title {
  font-weight: bold;
}
.list-menu .v-list-item--active {
  color: white !important;
}
.list-menu .v-application--is-ltr
  nav:not(.v-navigation-drawer--mini-variant)
  .v-list-item__action:first-child,
.list-menu .v-application--is-ltr
  nav:not(.v-navigation-drawer--mini-variant)
  .v-list-item__icon:first-child {
  margin-right: 20px !important;
  transition: 0.2s linear;
}

.list-menu .v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 20px !important;
}

nav:not(.v-navigation-drawer--is-mouseover) .logo-normal:not(.show-logo) {
  display: none;
}

.logo-normal {
  font-size: 1.5rem;
  font-weight: bold;
}

.company-icon{
  height: 32px !important;
  border-radius: 0 !important;
}
.list-menu .v-list-item__icon {
  min-width: 36px !important;
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item {
  justify-content: left !important;
}

.v-list-group__header.v-list-item--active:not(:hover):not(:focus):before {
    opacity: .24 !important;
}
</style>
